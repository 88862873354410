/* General Dashboard Styles */
.dashboard-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #f8f9fa;
  font-family: Arial, sans-serif;
}

/* Header Section */
.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #167378;
  color: white;
  padding: 15px 30px;
  font-size: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.logo-section {
  display: flex;
  align-items: center;
}

.logo-image {
  height: 40px;
  margin-right: 10px;
}

.dashboard-title {
  font-weight: bold;
  font-size: 24px;
}

/* Logout Button */
.logout-btn {
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.logout-btn:hover {
  background-color: #b52a3a;
}

/* Main Section */
.dashboard-main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
}

.dashboard-main h1 {
  font-size: 32px;
  color: #333;
  margin-bottom: 10px;
}

.dashboard-main p {
  font-size: 18px;
  color: #555;
  margin-bottom: 20px;
}

/* Action Button */
.action-btn {
  background-color: #167378;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 15px 30px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.action-btn:hover {
  background-color: #125e60;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .dashboard-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .logout-btn {
    margin-top: 10px;
  }

  .dashboard-main h1 {
    font-size: 28px;
  }

  .action-btn {
    padding: 10px 20px;
  }
}
