.verify-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.verify-container h1 {
    margin-bottom: 10px;
    color: #166f78;
}

.verify-container p {
    margin-bottom: 20px;
    color: #666;
}

.form-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

input[type="text"] {
    padding: 10px;
    width: 100%;
    max-width: 400px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

button {
    padding: 10px 20px;
    background: #166f78;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
}

button[disabled] {
    background: #ccc;
    cursor: not-allowed;
}

.result {
    margin-top: 20px;
    padding: 20px;
    border-radius: 8px;
}

.result.valid {
    background: #e8f5e9;
    color: #2e7d32;
}

.result.invalid {
    background: #ffebee;
    color: #c62828;
}

.result.error {
    background: #fff3e0;
    color: #e65100;
}
.logo {
    width: 80px;  /* Adjust width as needed */
    height: auto;  /* Maintains aspect ratio */
    display: block;
    margin: 0 auto 10px; /* Centers the logo */
  }
  .verified-icon {
    width: 28px;
    height: 28px;
    margin-right: 8px;
    vertical-align: middle;
  }