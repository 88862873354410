/* Ensure the entire window is dark, matching the background */
html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  background: #0f172a; /* Solid dark navy from the gradient */
  overflow-x: hidden; /* Prevent horizontal scroll */
}

.report-generator-container {
  min-height: 100vh;
  background: #0f172a; /* Consistent solid dark background */
  font-family: 'Roboto Mono', monospace;
  color: #d1d5db; /* Light gray for text */
  display: flex;
  flex-direction: column;
}

/* Logo section */
.logo-section {
  padding: 15px 20px;
  display: flex;
  align-items: center;
  background: #0f172a; /* Match container background */
}

.logo-image {
  height: 40px;
  margin-right: 10px;
  filter: drop-shadow(0 0 5px rgba(20, 184, 166, 0.3)); /* Subtle Auraplan teal glow */
}

.logo-text {
  font-size: 20px;
  font-weight: 600;
  color: #14b8a6; /* Auraplan teal */
  text-transform: uppercase;
  letter-spacing: 1px;
  text-shadow: 0 0 5px rgba(20, 184, 166, 0.4);
}

/* Main content */
.report-generator-main {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: #0f172a; /* Ensure main matches container */
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 900px;
  width: 100%;
}

.title-section {
  text-align: center;
  margin-bottom: 20px;
}

.title-section h1 {
  font-size: 36px;
  color: #14b8a6; /* Auraplan teal */
  text-transform: uppercase;
  letter-spacing: 3px;
  text-shadow: 0 0 12px rgba(20, 184, 166, 0.5);
  margin: 0;
}

.title-section h2 {
  font-size: 18px;
  color: #9ca3af; /* Softer gray */
  font-weight: 400;
  letter-spacing: 1px;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.1);
  margin: 5px 0 0;
}

.form-and-description {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}

.report-form {
  background: rgba(255, 255, 255, 0.05);
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  width: 50%;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.input-group {
  margin-bottom: 15px;
}

.input-group label {
  font-size: 12px;
  font-weight: 500;
  color: #14b8a6; /* Auraplan teal */
  margin-bottom: 6px;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: block;
}

.input-group input {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.02);
  color: #d1d5db;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.input-group input:focus {
  outline: none;
  border-color: #14b8a6;
  box-shadow: 0 0 8px rgba(20, 184, 166, 0.5);
}

.submit-btn {
  width: 100%;
  padding: 12px;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  background: linear-gradient(45deg, #14b8a6 0%, #2dd4bf 100%); /* Teal to lighter teal */
  border: none;
  border-radius: 6px;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  box-shadow: 0 0 12px rgba(20, 184, 166, 0.3);
}

.submit-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 0 18px rgba(20, 184, 166, 0.6);
}

.status-message {
  margin-top: 15px;
  font-size: 14px;
  text-align: center;
  padding: 8px;
  border-radius: 4px;
  transition: opacity 0.3s ease;
}

.status-message.success {
  color: #34d399; /* Bright green for success */
  background: rgba(52, 211, 153, 0.1);
  border: 1px solid rgba(52, 211, 153, 0.3);
}

.status-message.error {
  color: #f87171; /* Softer red for error */
  background: rgba(248, 113, 113, 0.1);
  border: 1px solid rgba(248, 113, 113, 0.3);
}

.description {
  width: 45%;
  background: rgba(255, 255, 255, 0.05);
  padding: 15px;
  border-radius: 12px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.description p {
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 10px;
}

.description ul {
  padding-left: 15px;
  font-size: 12px;
  line-height: 1.6;
  color: #9ca3af;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .form-and-description {
    flex-direction: column;
    gap: 15px;
  }

  .report-form, .description {
    width: 100%;
  }

  .logo-section {
    padding: 10px 15px;
  }

  .logo-text {
    font-size: 18px;
  }

  .title-section h1 {
    font-size: 30px;
  }

  .title-section h2 {
    font-size: 16px;
  }
}