.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 600px;
    max-height: 90%;
    overflow-y: auto;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.modal-content h2 {
    margin-bottom: 20px;
    text-align: center;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group input,
.form-group select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.form-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.btn-primary {
    background-color: #167378;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.btn-secondary {
    background-color: #ccc;
    color: #333;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.btn-primary:hover {
    background-color: #125e60;
}

.btn-secondary:hover {
    background-color: #aaa;
}

/* Toggle container */
.toggle-container {
    position: relative;
    width: 50px;
    height: 24px;
    display: inline-block;
    margin-left: 10px;
    vertical-align: middle;
  }
  
  /* Hide default checkbox */
  .toggle-container input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* Slider */
  .toggle-container .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
  }
  
  /* Slider handle */
  .toggle-container .slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
  
  /* Toggle on */
  .toggle-container input:checked + .slider {
    background-color: #4CAF50;
  }
  
  /* Move handle on toggle */
  .toggle-container input:checked + .slider:before {
    transform: translateX(26px);
  }
  
  /* Label styling */
  .toggle-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
/* Custom segmented toggle styles using Material‑UI with external CSS */

/* This style applies to both Version and Organisation toggles */
.custom-toggle-group {
  display: inline-flex;
  border-radius: 6px; /* Slightly rounded corners */
  border: none; /* Use Auraplan green as border */
}

.custom-toggle-group .MuiToggleButton-root {
  padding: 0 12px;
  min-height: 32px;
  border: none;
  border-radius: 10px; /* Slight roundness */
  text-transform: none;
  line-height: 32px;
  background-color: #f2eeee; /* Unselected: white background */
  color: #898787 ; /* Unselected: text in Auraplan green */
  transition: background-color 0.3s, color 0.3s;
}

.custom-toggle-group .MuiToggleButton-root:hover {
  background-color: #d0c4c4;
}

.custom-toggle-group .Mui-selected {
  background-color: #167378 !important; /* Selected: Auraplan green */
  color: #fff !important;
}

.custom-toggle-group .MuiToggleButtonGroup-grouped {
  border-right: none; 
}
