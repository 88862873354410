/* Minimalist spinner */
.minimal-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1); /* Light gray border */
    border-top: 4px solid #3498db; /* Blue border on top */
    border-radius: 50%; /* Circular shape */
    width: 40px; /* Adjust size */
    height: 40px; /* Adjust size */
    animation: spin 0.8s linear infinite; /* Continuous rotation */
  }
  
  /* Keyframes for spinning animation */
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  