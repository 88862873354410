body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f9f9f9;
    color: #333;
  }
  
  button {
    padding: 10px 20px;
    border: none;
    background-color: #0056b3;
    color: white;
    cursor: pointer;
    margin: 10px;
    border-radius: 5px;
  }
  
  button:hover {
    background-color: #003f7f;
  }
  
  .error {
    color: red;
    font-size: 0.9em;
  }
  html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f8f9fa; /* Optional: Match dashboard background */
}

.verify-container {
  text-align: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.certificate-card {
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 8px;
  display: inline-block;
  margin-top: 20px;
  background: white;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.error {
  color: red;
}