/* Global Styling */
body {
  margin: 0;
  font-family: "Lato", sans-serif;
  background: linear-gradient(to bottom, #167378, #232323);
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  color: #676767;
}

/* Login Page Styling */
.login-page {
  display: flex;
  background: linear-gradient(to bottom, #167378, #232323);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

/* Header Section */
.login-header {
  background-color: #167378; /* Auraplan green */
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  color: white;
  width: 100%;
  max-width: 400px; /* Align with the login box */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Optional for a polished look */
  margin-bottom: 20px;
}

.login-logo {
  width: 60px;
  height: auto;
  display: block;
  margin: 0 auto 10px; /* Center and space below */
}

.login-header h1 {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 10px 0 0;
}

/* Login Container Styling */
.login-container {
  background: #ffffff;
  width: 90%;
  max-width: 400px;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.login-container h2 {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color: #167378;
}

.input-group {
  margin-bottom: 1.5rem;
  text-align: left;
}

label {
  display: block;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #333;
}

input {
  width: 100%;
  padding: 0.8rem;
  font-size: 1rem;
  border: 1px solid rgba(218, 218, 218, 0.5);
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box; /* Ensures proper spacing */
}

input:focus {
  outline: none;
  border: 1px solid #167378;
}

/* Error Message Styling */
.error-message {
  color: #ca4141; /* Red for error */
  margin-bottom: 1rem;
  font-size: 0.9rem;
}

/* Login Button Styling */
.login-button {
  width: 50%;
  padding: 0.7rem;
  font-size: 1rem;
  color: white;
  background-color: #167378;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

.login-button:hover {
  background-color: #135e60;
}

/* Footer Styling */
.login-footer {
  text-align: center;
  margin-top: 1rem;
  color: white;
  font-size: 0.9rem;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .login-container {
    padding: 1.5rem;
  }

  .login-header {
    padding: 15px;
    font-size: 1.2rem;
  }
}
